<template>
    <base-section>
        <base-section-heading
            color="grey lighten-2"
            icon="mdi-check"
            title="About Our Company Services"
        />

        <v-container>
            <v-row>
                <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
                    <base-info-card v-bind="card" />
                </v-col>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
        cards: [
            {
                title: 'Durable Raw Material ',
                subtitle: 'Durable Products',
                text:
                    ' Our products (raw materials) are durable and of good quality.',
                callout: '01',
            },
            {
                title: 'Reliable and Quick Delivery',
                subtitle: 'Swift Delivery',
                text:
                    'We are reliable and quick in delivery of our goods and services, as fast as possible.',
                callout: '02',
            },
            {
                title: 'Registered With CAC',
                subtitle: 'CAC Registration',
                text:
                    'Our company is incorporated at the Cooperate Affairs Commission (CAC) with No.RC1348254',
                callout: '03',
            },
        ],
    }),
};
</script>
<style>
#about-our-product {
    margin-top: -170px;
}
</style>
